<template>
  
<div>
  <router-view />

</div>
 

</template>

<script>

</script>
