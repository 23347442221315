<template>
    <div>
      <!-- Render only the route's component -->
      <router-view />
    </div>
  </template>
  
  <script>
  export default {
    name: "BlankLayout",
  };
  </script>
  
  <style scoped>
  /* Add styles if needed for this layout */
  </style>