<template>
  <div class="home">
    <div class="catTop container">
      <CategoryHomeVue class="category-home"/>
      <div class="rightTop">
        <div class="itemsCarousel">
          <CarouselHomeVue />
          <CategoryMediaVue/>
          <ProductListHomeVue />
        </div>
        <!-- <router-link to="/category/:categoryId" class="bg-wall-link bg-wall">
          
        </router-link> -->
      </div>
      
    </div>
    <MostViewed :mostViewedProducts="mostViewedProducts" />
    <MostSold :mostSoldProducts="mostSoldProducts" />
    <AllProductsHome :allProducts="allProducts" />
  </div>
</template>



<script>
import MostSold from "@/components/MostSold.vue";
import MostViewed from "@/components/MostViewed.vue";
import CategoryHomeVue from "@/components/CategoryHome.vue";
import CarouselHomeVue from "@/components/CarouselHome.vue";
import ProductListHomeVue from "@/components/ProductListHome.vue";
import CategoryMediaVue from "@/components/CategoryMedia.vue";
import AllProductsHome from "@/components/AllProductsHome.vue";

export default {
  components: {
    CategoryHomeVue,
    CarouselHomeVue,
    ProductListHomeVue,
    CategoryMediaVue,
    MostViewed,
    MostSold,
    AllProductsHome,
  },
  data() {
    return {
      mostViewedProducts: [
        {
          id: 3,
          name: "ПВС 2x1,5 электр сими",
          price: "5 818",
          image: "https://rahmat.uz/photos/products_xl/5180qupVW8Ue.png",
        },
        // More products...
      ],
      mostSoldProducts: [
        {
          id: 2,
          name: "Product A",
          price: "7,000",
          image: "https://rahmat.uz/photos/products_xl/5180qupVW8Ue.png",
        },
        // More products...
      ],
      allProducts: [
      {
        id: 1, // Unique identifier
        name: "PUNP 2x1,5 elektr simi",
        price: "4,776",
        image: "https://via.placeholder.com/150",
      },
      // More products...
    ],
    };
  },
  methods: {
    navigateToProductDetail(product) {
      this.$router.push({
        name: "ProductDetail",
        query: { product: JSON.stringify(product) },
      });
    },
  },
};
</script>

<style scoped>

.rightTop{
  display: flex;
}
.catTop {
  display: flex;
  justify-content: center;
  width: 100%;

}

.bg-wall {
  width: 250px;
  background-image: url(../assets/simRight.jpg);
  background-size: cover;
  background-position: center;
  margin-top: 20px;
}
@media only screen and (max-width: 850px) {
  .category-home{
    display: none!important;
  }
}
@media only screen and (max-width: 1350px) {

.rightTop{
  justify-content: center;
}
.bg-wall{
  width: 200px;

}
}
@media only screen and (max-width: 900px) {
.bg-wall{
  display: none;
}
}
.container {
    width: 100%;
    
    margin-top: 30px;
    margin-right: auto;
    margin-left: auto;
    background-color: transparent;
}

@media (min-width: 1346px) {
    .container {
        max-width: 1366px;
    }
}

@media (min-width: 1250px) {
    .container {
        max-width: 1250px;
    }
}
</style>
